<template>
  <div class="roles v-step-all-roles">
    <ul>
      <li @dblclick="!checkCurrentPermissions()('police') ? '' : $emit('doubleClickSelect')">
        <input
          v-model="selectedRole"
          name="role-selection"
          id="police"
          type="radio"
          value="police"
          :disabled="!checkCurrentPermissions()('police')"
        />
        <label for="police">
          <div
            class="_box"
            :class="{ active: selectedRole === 'police', disabled: !checkCurrentPermissions()('police') }"
          >
            <div class="banner blue" v-show="!checkCurrentPermissions()('police')">
              <h2 class="primary">{{ $t('selection.roles.no-access') }}</h2>
            </div>
            <div class="image">
              <img src="//cdn.leafcad.com/images/icons/application/policecap.png" alt="role-image" />
            </div>
            <div class="name">
              <h2 class="primary">{{ $t('selection.roles.police') }}</h2>
            </div>
          </div>
        </label>
      </li>
      <li @dblclick="!checkCurrentPermissions()('paramedic') ? '' : $emit('doubleClickSelect')">
        <input
          v-model="selectedRole"
          name="role-selection"
          id="paramedic"
          type="radio"
          value="paramedic"
          :disabled="!checkCurrentPermissions()('paramedic')"
        />
        <label for="paramedic">
          <div
            class="_box disabled"
            :class="{ active: selectedRole === 'paramedic', disabled: !checkCurrentPermissions()('paramedic') }"
          >
            <div class="banner blue" v-show="!checkCurrentPermissions()('paramedic')">
              <h2 class="primary">{{ $t('selection.roles.coming-soon') }}</h2>
            </div>
            <div class="image">
              <img src="//cdn.leafcad.com/images/icons/application/paramedic.png" alt="role-image" />
            </div>
            <div class="name">
              <h2 class="primary">{{ $t('selection.roles.paramedic') }}</h2>
            </div>
          </div>
        </label>
      </li>
      <li @dblclick="!checkCurrentPermissions()('firefighter') ? '' : $emit('doubleClickSelect')">
        <input
          v-model="selectedRole"
          name="role-selection"
          id="firefighter"
          type="radio"
          value="firefighter"
          :disabled="!checkCurrentPermissions()('firefighter')"
        />
        <label for="firefighter">
          <div
            class="_box disabled"
            :class="{ active: selectedRole === 'firefighter', disabled: !checkCurrentPermissions()('firefighter') }"
          >
            <div class="banner blue" v-show="!checkCurrentPermissions()('firefighter')">
              <h2 class="primary">{{ $t('selection.roles.coming-soon') }}</h2>
            </div>
            <div class="image">
              <img src="//cdn.leafcad.com/images/icons/application/firefighterhelmet.png" alt="role-image" />
            </div>
            <div class="name">
              <h2 class="primary">{{ $t('selection.roles.firefighter') }}</h2>
            </div>
          </div>
        </label>
      </li>
      <li @dblclick="!checkCurrentPermissions()('civilian') ? '' : $emit('doubleClickSelect')">
        <input
          v-model="selectedRole"
          name="role-selection"
          id="civilian"
          type="radio"
          value="civilian"
          :disabled="!checkCurrentPermissions()('civilian')"
        />
        <label for="civilian">
          <div
            class="_box"
            :class="{ active: selectedRole === 'civilian', disabled: !checkCurrentPermissions()('civilian') }"
          >
            <div class="banner blue" v-show="!checkCurrentPermissions()('civilian')">
              <h2 class="primary">{{ $t('selection.roles.no-access') }}</h2>
            </div>
            <div class="image">
              <img src="//cdn.leafcad.com/images/icons/application/civil.png" alt="role-image" />
            </div>
            <div class="name">
              <h2 class="primary">{{ $t('selection.roles.civilian') }}</h2>
            </div>
          </div>
        </label>
      </li>
      <li @dblclick="!checkCurrentPermissions()('dispatch') ? '' : $emit('doubleClickSelect')">
        <input
          v-model="selectedRole"
          name="role-selection"
          id="dispatch"
          type="radio"
          value="dispatch"
          :disabled="!checkCurrentPermissions()('dispatch')"
        />
        <label for="dispatch">
          <div
            class="_box"
            :class="{ active: selectedRole === 'dispatch', disabled: !checkCurrentPermissions()('dispatch') }"
          >
            <div class="banner blue" v-show="!checkCurrentPermissions()('dispatch')">
              <h2 class="primary">{{ $t('selection.roles.no-access') }}</h2>
            </div>
            <div class="image">
              <img src="//cdn.leafcad.com/images/icons/application/911call.png" alt="role-image" />
            </div>
            <div class="name">
              <h2 class="primary">{{ $t('selection.roles.dispatch') }}</h2>
            </div>
          </div>
        </label>
      </li>
      <li @dblclick="!checkCurrentPermissions()('admin') ? '' : $emit('doubleClickSelect')">
        <input
          v-model="selectedRole"
          name="role-selection"
          id="admin"
          type="radio"
          value="admin"
          :disabled="!checkCurrentPermissions()('admin') && !checkCurrentPermissions()('owner')"
        />
        <label for="admin">
          <div
            class="_box"
            :class="{
              active: selectedRole === 'admin',
              disabled: !checkCurrentPermissions()('admin') && !checkCurrentPermissions()('owner')
            }"
          >
            <div
              class="banner blue"
              v-show="!checkCurrentPermissions()('admin') && !checkCurrentPermissions()('owner')"
            >
              <h2 class="primary">{{ $t('selection.roles.no-access') }}</h2>
            </div>
            <div class="image">
              <img src="//cdn.leafcad.com/images/icons/application/admin.png" alt="role-image" />
            </div>
            <div class="name">
              <h2 class="primary">{{ $t('selection.roles.admin') }}</h2>
            </div>
          </div>
        </label>
      </li>
      <li class="ad">
        <Adsense
          ins-style="display:inline-block;width:245px;height:280px"
          data-ad-client="ca-pub-7037140739891615"
          data-ad-slot="9386554443"
        >
        </Adsense>
      </li>
    </ul>

    <v-tour
      :name="this.$route.name"
      :steps="tourSteps"
      :callbacks="finishCallbackOnly"
      :options="translatedLabels"
    ></v-tour>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/selection/selection-boxes.less"></style>

<script>
import { mapGetters } from 'vuex'
import TourManager from '@/mixins/TourManager'

export default {
  name: 'Roles',
  mixins: [TourManager],
  data() {
    return {
      tourSteps: [
        {
          target: '.v-step-all-roles',
          content: this.$t('tour.roles.all-roles-1'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-all-roles',
          content: this.$t('tour.roles.all-roles-2'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-continue-button',
          content: this.$t('tour.roles.continue-button'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        }
      ]
    }
  },
  methods: {
    ...mapGetters('auth', ['checkCurrentPermissions'])
  },
  computed: {
    selectedRole: {
      set(role) {
        this.$store.commit('selection/role', role)
      },
      get() {
        if (this.$store.state.selection.role === null) {
          return 0
        }

        return this.$store.state.selection.role
      }
    }
  },
  created() {
    if (
      this.$store.state.selection.community === null ||
      (!this.checkCurrentPermissions()('accepted') && !this.checkCurrentPermissions()('owner'))
    ) {
      this.$router.push({ name: 'communities' })
    }
  }
}
</script>
